console.log(process.env.REACT_APP_VERSION);

export async function getMenuData() {
  return [
    {
      title: 'Results',
      key: 'results',
      url: '/locations',
      icon: 'fe fe-compass',
    },
    {
      title: 'Users',
      key: 'users',
      url: '/users',
      icon: 'fe fe-user',
    },
    {
      title: 'Companies',
      key: 'companies',
      icon: 'fe fe-briefcase',
      url: '/companies',
    },
    {
      title: 'Packing Slip',
      key: 'packingSlip',
      url: '/packing-slip',
      icon: 'fe fe-download',
    },
    {
      title: 'Intake',
      key: 'intake',
      url: '/intake',
      icon: 'fe fe-monitor',
    },
    {
      title: 'Email Support',
      key: 'contactUs',
      icon: 'fe fe-mail',
    },
    {
      title: 'Help Center',
      key: 'helpCenter',
      icon: 'fa fa-question-circle-o',
    },
    // TODO: fix menu
    // {
    //   title: `v ${process.env.REACT_APP_VERSION}`,
    //   key: 'version',
    //   icon: 'fe fe-git-merge',
    // },
  ];
}

export async function getRolePermissions() {
  return {
    admin: {
      permitted: [
        '/profile',
        '/users',
        '/companies',
        '/packing-slip',
        '/intake',
      ],
      default: '/users',
    },
    staff: {
      permitted: [
        '/profile',
        '/users',
        '/companies',
        '/packing-slip',
        '/intake',
      ],
      default: '/users',
    },
    'company-admin': {
      permitted: ['/profile', '/locations', '/packing-slip'],
      default: '/locations',
    },
  };
}
