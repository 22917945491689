const actions = {
  ON_COMPANY_DATA_CHANGE: 'companies/ON_COMPANY_DATA_CHANGE',

  CREATE_COMPANY_REQUEST: 'companies/CREATE_COMPANY_REQUEST',
  CREATE_COMPANY_SUCCESS: 'companies/CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_FAILURE: 'companies/CREATE_COMPANY_FAILURE',

  GET_COMPANY_REQUEST: 'companies/GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS: 'companies/GET_COMPANY_SUCCESS',
  GET_COMPANY_FAILURE: 'companies/GET_COMPANY_FAILURE',

  UPDATE_USERS_REQUEST: 'companies/UPDATE_USERS_REQUEST',
  UPDATE_USERS_SUCCESS: 'companies/UPDATE_USERS_SUCCESS',
  UPDATE_USERS_FAILURE: 'companies/UPDATE_USERS_FAILURE',

  FETCH_COMPANIES_REQUEST: 'companies/FETCH_COMPANIES_REQUEST',
  FETCH_COMPANIES_SUCCESS: 'companies/FETCH_COMPANIES_SUCCESS',
  FETCH_COMPANIES_FAILURE: 'companies/FETCH_COMPANIES_FAILURE',
};

export default actions;
