const config = {
  mirimus: {
    name: 'Mirimus',
    id: 'mirimus',
    contacts: {
      email: 'testingsupport@mirimus.com',
    },
    termsUrl: './mirimus-terms',
    shipmentInfo: {
      labName: 'Mirimus Clinical Labs',
      streetAddress: '760 Parkside Avenue',
      city: 'Brooklyn, NY 11226',
    },
    timezone: 'America/New_York',
  },
  aclv: {
    name: 'Accelevir',
    id: 'aclv',
    contacts: {
      email: 'mhays@accelevir.com',
    },
    termsUrl: './aclv-terms',
    shipmentInfo: {
      labName: 'Accelevir Diagnostics',
      streetAddress: '701 E Pratt St.',
      city: 'Baltimore, MD',
    },
    timezone: 'America/New_York',
  },
  wynn: {
    name: 'Mirimus',
    id: 'wynn',
    contacts: {
      email: 'testingsupport@mirimus.com',
    },
    termsUrl: './mirimus-terms',
    shipmentInfo: null,
    timezone: 'America/Los_Angeles',
  },
};

export default config;
