import actions from './actions';

const initialState = {
  url: '',
  fedexImg: null,
  isLoading: false,
};

export default function intakeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_PACKING_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actions.CREATE_PACKING_SUCCESS: {
      return {
        ...state,
        url: action.payload.url,
        fedexImg: action.payload.fedexImg,
        isLoading: false,
      };
    }
    case actions.CREATE_PACKING_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
