import { combineReducers } from 'redux';
import actions from './actions';

const initialState = {
  items: [],
  chartsData: [],
  error: null,
  isLoading: false,
  chartsLoading: false,
  total: 0,
  page: 1,
};

const timelineReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_TIMELINE_REQUEST: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case actions.LOAD_TIMELINE_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        items: data,
        isLoading: true,
      };
    }
    case actions.LOAD_TIMELINE_FAILURE:
      return {
        ...state,
        isLoading: true,
      };

    case actions.FETCH_CHARTS_INFO_REQUEST: {
      return {
        ...state,
        chartsLoading: true,
      };
    }
    case actions.FETCH_CHARTS_INFO_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        chartsData: data,
        chartsLoading: false,
      };
    }
    case actions.FETCH_CHARTS_INFO_FAILURE:
      return {
        ...state,
        chartsLoading: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  all: timelineReducer,
});
