const actions = {
  FETCH_CUSTOMERS_REQUEST: 'customers/FETCH_CUSTOMERS_REQUEST',
  FETCH_CUSTOMERS_SUCCESS: 'customers/FETCH_CUSTOMERS_SUCCESS',
  FETCH_CUSTOMERS_FAILURE: 'customers/FETCH_CUSTOMERS_FAILURE',

  SET_STATUS_REQUEST: 'customers/SET_STATUS_REQUEST',
  SET_STATUS_SUCCESS: 'customers/SET_STATUS_SUCCESS',

  REINVITE_REQUEST: 'customers/REINVITE_REQUEST',
  REINVITE_SUCCESS: 'customers/REINVITE_SUCCESS',
  REINVITE_FAILURE: 'uscustomerser/REINVITE_FAILURE',

  FETCH_COMPANIES_REQUEST: 'customers/FETCH_COMPANIES_REQUEST',
  FETCH_COMPANIES_SUCCESS: 'customers/FETCH_COMPANIES_SUCCESS',
  FETCH_COMPANIES_FAILURE: 'customers/FETCH_COMPANIES_FAILURE',

  INVITE_CUSTOMER_REQUEST: 'customers/INVITE_CUSTOMER_REQUEST',
  INVITE_CUSTOMER_SUCCESS: 'customers/INVITE_CUSTOMER_SUCCESS',
  INVITE_CUSTOMER_FAILURE: 'customers/INVITE_CUSTOMER_FAILURE',
};

export default actions;
