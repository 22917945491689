const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN_REQUEST: 'user/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'user/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'user/LOGIN_FAILURE',
  FORGOT_REQUEST: 'user/FORGOT_REQUEST',
  FORGOT_SUCCESS: 'user/FORGOT_SUCCESS',
  FORGOT_FAILURE: 'user/FORGOT_FAILURE',
  RESTORE_REQUEST: 'user/RESTORE_REQUEST',
  RESTORE_SUCCESS: 'user/RESTORE_SUCCESS',
  RESTORE_FAILURE: 'user/RESTORE_FAILURE',
  LOGOUT: 'user/LOGOUT',
  ACCEPT_REQUEST: 'user/ACCEPT_REQUEST',
  ACCEPT_SUCCESS: 'user/ACCEPT_SUCCESS',
  ACCEPT_FAILURE: 'user/ACCEPT_FAILURE',
  PROFILE_REQUEST: 'user/PROFILE_REQUEST',
  PROFILE_SUCCESS: 'user/PROFILE_SUCCESS',
  PROFILE_FAILURE: 'user/PROFILE_FAILURE',
  UPDATE_PROFILE_REQUEST: 'user/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'user/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'user/UPDATE_PROFILE_FAILURE',
  CHANGE_PASSWORD_REQUEST: 'user/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'user/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'user/CHANGE_PASSWORD_FAILURE',
  REPLACE_ROLE: 'user/REPLACE_ROLE',

  VERIFY_EMAIL_REQUEST: 'reg/VERIFY_EMAIL_REQUEST',
  VERIFY_EMAIL_SUCCESS: 'reg/VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILURE: 'reg/VERIFY_EMAIL_FAILURE',

  REG_BY_EMAIL_REQUEST: 'reg/REG_BY_EMAIL_REQUEST',
  REG_BY_EMAIL_SUCCESS: 'reg/REG_BY_EMAIL_SUCCESS',
  REG_BY_EMAIL_FAILURE: 'reg/REG_BY_EMAIL_FAILURE',
};

export default actions;
