const actions = {
  LOAD_TIMELINE_REQUEST: 'timeline/LOAD_TIMELINE_REQUEST',
  LOAD_TIMELINE_SUCCESS: 'timeline/LOAD_TIMELINE_SUCCESS',
  LOAD_TIMELINE_FAILURE: 'timeline/LOAD_TIMELINE_FAILURE',

  DOWNLOAD_REQUEST: 'timeline/DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'timeline/DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'timeline/DOWNLOAD_FAILURE',

  FETCH_CHARTS_INFO_REQUEST: 'timeline/FETCH_CHARTS_INFO_REQUEST',
  FETCH_CHARTS_INFO_SUCCESS: 'timeline/FETCH_CHARTS_INFO_SUCCESS',
  FETCH_CHARTS_INFO_FAILURE: 'timeline/FETCH_CHARTS_INFO_FAILURE',
};

export default actions;
