const actions = {
  FETCH_ACTIVITY_BY_USER_ID_REQUEST:
    'activityStream/FETCH_ACTIVITY_BY_USER_ID_REQUEST',
  FETCH_ACTIVITY_BY_USER_ID_SUCCESS:
    'activityStream/FETCH_ACTIVITY_BY_USER_ID_SUCCESS',
  FETCH_ACTIVITY_BY_USER_ID_FAILURE:
    'activityStream/FETCH_ACTIVITY_BY_USER_ID_FAILURE',
  FETCH_USER_BY_ID_REQUEST: 'activityStream/FETCH_USER_BY_ID_REQUEST',
  FETCH_USER_BY_ID_SUCCESS: 'activityStream/FETCH_USER_BY_ID_SUCCESS',
  FETCH_USER_BY_ID_FAILURE: 'activityStream/FETCH_USER_BY_ID_FAILURE',
};

export default actions;
